<template>
    <div class="page" id="reorganize">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-form-item prop="description">
                <el-input size="small" maxlength="66" v-model="searchForm.description" placeholder="请输入报表名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search()" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <div class="f_r m_r1">
                <el-button type="primary" @click="view('add', null)" size="small" icon="el-icon-plus">新建</el-button>
            </div>
        </el-form>
        <div class="top bg-white">
            <!--            <el-button  type="danger" size="small" icon="el-icon-delete" @click="del()" :disabled="dataListSelections.length <= 0" plain>删除</el-button>-->
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100% - 80px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        type="selection"
                        header-align="center"
                        align="center"
                        width="50">
                </el-table-column>
                <el-table-column prop="name" label="报表名称"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="reportType" label="报表类型">
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("report_type", scope.row.reportType, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="templateName" label="是否启用">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.enable" :active-value="0" :inactive-value="1" @change="up(scope.row)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="250">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" plain @click="view('edit', scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger" plain @click="del(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <addForm ref="addForm" @refreshDataList="refreshList"></addForm>
    </div>
</template>

<script>
    import addForm from './addForm'
    export default {
        data() {
            return {
                searchForm: {
                    description: ''
                },
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListSelections: [],
                loading: false
            }
        },
        components: {
            addForm
        },
        activated() {
            this.refreshList()
        },
        methods: {
            search() {
                this.pageNo = 1;
                this.refreshList();
            },
            // 获取数据列表
            refreshList() {
                this.loading = true
                this.$axios(this.api.zlzs.templateList, {
                    'name': this.searchForm.description,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        console.log(data);
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            //修改
            up(row){
                this.$axios(this.api.zlzs.templateUpdateById, {
                    id:row.id,
                    enable:row.enable,
                }, 'put').then(data => {
                    if (data && data.status) {
                        this.refreshList();
                    }
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 查看
            view(method, row) {
                // if(row.enable==0){
                    this.$refs.addForm.init(method, row)
                // }else{
                //     this.$message.error("FTP已禁用")
                // }
            },
            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.templateRemoveById, {'id': ids}, 'delete').then(data => {
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.refreshList()
                        }else{
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            // 重置
            resetSearch() {
                this.$refs.searchForm.resetFields()
                this.search()
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        }
    }
</script>
